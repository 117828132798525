<template lang="pug">
.brand-loading-transition
  .d-flex.justify-content-center.align-items-center.h-100
    .brand-loading-transition-icon
      svg.anim(xmlns='http://www.w3.org/2000/svg', width='60', height='120', viewBox='0 0 60 89.9')
        g#Layer_2(data-name='Layer 2')
          g#base
            g
              circle.om-dot-one(cx='11', cy='11', r='11', fill='#ec5b2a')
              circle.om-dot-two(cx='49', cy='11', r='11', fill='#ec5b2a')
              circle.om-dot-three(cx='11', cy='44.7', r='11', fill='#ec5b2a')
              circle.om-dot-four(cx='49', cy='44.7', r='11', fill='#ec5b2a')
              circle.om-dot-five(cx='11', cy='78.9', r='11', fill='#ec5b2a')
              circle.om-dot-six(cx='49', cy='78.9', r='11', fill='#ec5b2a')
</template>

<script>
  export default {};
</script>
